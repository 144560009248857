


export default function PhysicalProjectPage()
{
    return <div className="whitecard slidesContainer">
        <h1 className="aligncenter"> MR. SYNTH Oscillator Toy</h1>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/5FAyFKfrF_0?si=fHs5X-3XgGsy0Y5d" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <p className="projectDescription">MR. SYNTH is a 555 timer chip based square wave oscillator. 
            The toy has two alligator clips, which use an object connected between them as a resistor to control the oscillator's fequency. 
            High resistance items will make the oscillator put out a low pitch, and low resistance items will make the oscillator put out a high pitch sound. 
            I made this for a friend's birthday in one hectic weekend.
            I included a potentiometer and a photoresistor in the box, so that it can be controlled with a knob or played like a theremin with a flashlight. 
            MR SYNTH can be controlled using any item that conducts electricity- you can even use your tongue! (but that hurts, haha).
            <br></br>
            <br></br>

            Eventually, I want to remake MR. SYNTH, and perhaps rebrand it to look like a 3D version of my frog mascot.  I'd also like to figure out how 
            to build an analog filter, but I don't know how to do that yet! I guess I'll just have to figure it out :)
            
            </p>
    </div>
}